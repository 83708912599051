/* eslint-disable */
import Cookies from 'js-cookie'
import {
  getFavorites,
  getServerUrl,
  getPartnerUrl,
} from './login';
//싸고 있는 app에서 쿠키를 저장, 다른 메뉴를 클릭 할 때마다 쿠키를 저장, 초기화진행
const app = {
  state: {
    appmain: {
      opened: Cookies.get('searchboxStatus') ? !!+Cookies.get('searchboxStatus') : true,
    },
    device: 'desktop',
    language: Cookies.get('language') || 'kr',
    langInfo: {
      label: null,
      message: null,
    },
    loading: false,
    favorites: [],
    serverUrl: 'localhost',
    partnerUrl: 'localhost',
  }, //돌연변이: 검색박스가 보여진 상태일 때 닫히는 상태로, 때마다 쿠키저장
  mutations: {
    HIDE_SEARCH_BOX: state => {
      state.appmain.opened = !state.appmain.opened
      if (state.appmain.opened) {
        Cookies.set('searchboxStatus', 1)
      } else {
        Cookies.set('searchboxStatus', 0)
      }
    },
    CLEAR_SEARCH_BOX: state => {
      state.appmain.opened = true
      Cookies.set('searchboxStatus', 1)
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_FAVORITES: (state, favorites) => {
      state.favorites = favorites
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
    SERVER_URL: (state, serverUrl) => {
      state.serverUrl = serverUrl
    },
    PARTNER_URL: (state, partnerUrl) => {
      state.partnerUrl = partnerUrl
    },
  }, //commit 수행, 객체처럼 넘겨줌
  actions: {
    hideSearchBox({
      commit
    }) {
      commit('HIDE_SEARCH_BOX')
    },
    clearSearchBox({
      commit
    }) {
      commit('CLEAR_SEARCH_BOX')
    },
    toggleSideBar({
      commit
    }) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({
      commit
    }, {
      withoutAnimation
    }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({
      commit
    }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setLanguage({
      commit
    }, language) {
      commit('SET_LANGUAGE', language);
    },
    GetFavorites({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        getFavorites(data.userId)
          .then(response => {
            const data = response.data;
            commit('SET_FAVORITES', data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    setLoading({
      commit
    }, loading) {
      commit('SET_LOADING', loading);
    },
    GetServerUrl({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getServerUrl()
          .then(response => {
            const data = response.data;
            commit('SERVER_URL', data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GetPartnerUrl({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getPartnerUrl()
          .then(response => {
            const data = response.data;
            commit('PARTNER_URL', data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
}

export default app
