<template>
  <div class="d-flex">
    <div class="header-btn-lg pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left username_top">
            <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-lg" variant="link" no-caret right>
              <span slot="button-content">
                <div class="widget-content-left ml-3 header-user-info">
                  <div class="widget-heading text-white"><span v-text="userName"></span></div>
                  <span class="material-icons header-user-icon">account_circle</span>
                </div>
              </span>
              <div class="dropdown-menu-header topUserDiv">
                <div class="dropdown-menu-header-inner bg-orange-custom">
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="text-white"><span class="material-icons-outlined">account_circle</span> <span v-text="userName"></span></div>
                          <div class="text-white"><span class="material-icons-outlined">business</span> <span v-text="userDeptName"></span></div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <q-btn
                            :loading="loading"
                            outline rounded
                            size="sm"
                            icon="logout"
                            color="white"
                            style="width: 90px"
                            @click="logout"
                          > 로그아웃
                          <template v-slot:loading>
                            <q-spinner-hourglass class="on-left" />
                            로그아웃...
                          </template>
                          </q-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs" style="height: 40px;">
                <ul class="nav flex-column">
                  <!-- 비밀번호 변경 -->
                  <li class="nav-item-header nav-item" style="cursor:pointer" @click="changePassword">{{$label('LBL0000504')}}</li>
                </ul>
              </div>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';

export default {
  components: {
  },

  data() {
    return {
      loading: false,
      userName: '',
      userDeptName: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '40%',
        isFull: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.userName = this.$store.getters.user.userName;
      this.userDeptName = this.$store.getters.user.deptName;
    },
    logout() {   
      this.$http.url = transactionConfig.auth.login.logout.url;
      this.$http.type = 'POST';
      this.$http.request(
        this.$store.dispatch('LogOut').then(() => {
          window.sessionStorage.removeItem('defaultPlantCd');
          location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
        })
      );
    },
    changePassword() {
      this.popupOptions.title = 'LBL0000504' // 비밀번호 변경
      this.popupOptions.target = () => import(`${'@/pages/common/user/changePw.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  },
};
</script>
<style lang="sass">
.username_top
  .btn-link:hover
    text-decoration: none !important
  .btn-link:active
    text-decoration: none !important
  .btn-link
    text-decoration: none !important
.topUserDiv
  .material-icons-outlined
    margin-top: -2px
</style>
