<template>
  <div>
    <q-table
      v-table-resizable
      :tableId="tableId"
      ref="compo-table"
      class="default-table"
      :class="[topBorderClass, (isDashboard ? 'isDashboardTable' : '')]"
      :style="{ 'height': height }"
      :card-class="cardClass"
      flat
      bordered
      :fullscreen="fullscreen"
      :separator="separator"
      :data="arrayTreeObj"
      :columns="arrangColumns"
      :row-key="rowKey"
      :selection="selection"
      :selected.sync="selected"
      :visible-columns="visibleColumns"
      :filter="filter"
      :dense="dense"
      :loading="loading"
      :no-data-label="noDataLabel"
      :no-results-label="noResultLabel"
      :pagination.sync="initialPagination"
      :hide-pagination="false"
      :virtual-scroll="!usePaging"
      :virtual-scroll-slice-size="!usePaging ? 20 : 20"
      :virtual-scroll-item-size="!usePaging ? 48 : 0"
      :virtual-scroll-sticky-size-start="!usePaging ? 48 : 0"
      :hide-bottom="hideBottom"
      :hide-header="hideHeader"
      @selection="changeSelection"
      @virtual-scroll="virtualScroll "
    >
      <!-- ### loading 처리 -->
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>


      <!-- ### 데이터 없음 / filtering 된 데이터 없음 문구 처리 -->
      <template v-slot:no-data="{ message }">
        <div class="full-width row flex-center text-accent q-gutter-sm gridNodataDiv">
          <q-icon size="2em" name="sentiment_dissatisfied" />
          <span>
            {{ message }}
          </span>
        </div>
      </template>

      <!-- 상단 타이틀 / 컬럼 control -->
      <template v-slot:top v-if="isTop">
        <template v-if="isTitle">
          <div class="float-left">
            <!-- 타이틀 앞 표시영역 -->
            <slot name="perfixTitle"></slot>
            <!-- 타이틀 영역 -->
            <div class="q-table__title">
              <!-- <i class="text-primary lnr-list"></i>  -->
              <i class="pe-7s-angle-right-circle"></i>
              {{convertTitle}}
              <slot name="suffixTitle"></slot>
            </div>
            <!-- 타이틀 뒤 표시영역 -->
          </div>
          <!-- 필터링 영역 -->
          <div class="float-left" :class="[{'gridSearchIcon' : isTitle }, {'gridSearchIcon2' : !isTitle }]">
            <q-input
              v-if="filtering"
              class="filtering-text"
              dense
              debounce="300"
              color="white"
              style="min-width: 150px;max-width: 250px;"
              placeholder="Search"
              v-model="filter" >
              <template v-slot:append>
                <q-icon name="search"></q-icon>
              </template>
            </q-input>
            <slot name="customFilter"></slot>
          </div>
          <!-- chip 추가 -->
          <div>
            <slot name="table-chip"></slot>
          </div>
          <q-space />
          <!-- 버튼 영역 -->
          <div class="q-mt-md gridbtntop">
            <slot name="table-button"></slot>
          </div>
          <div class="float-right">
          <!-- 설명 영역 -->
            <slot name="prefixContent"></slot> 
            <q-btn v-if="isExcelDown && data.length > 0" flat dense class="tableExcelIcon" @click="downloadExcel">
              <q-tooltip anchor="top left" self="center left">
                <!-- 엑셀 다운로드 -->
                {{$label('LBL0000569')}}
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="isFullScreen"
              flat
              dense
              color="grey-6"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              class="tabletopicon tablefullscreen"
              @click="clickFullScreen">
              <q-tooltip v-if="fullscreen">
                <!-- 원래대로 -->
                {{$label('LBL0000570')}}
              </q-tooltip>
              <q-tooltip anchor="top left" self="center left" v-else>
                <!-- 전체화면 -->
                {{$label('LBL0000571')}}
              </q-tooltip>
            </q-btn>
            <!-- 컬럼 컨트롤 영역 -->
            <div v-if="columnSetting" class="table-setting gridsettingIcon">
              <q-btn
                flat
                dense
                color="grey-6"
                icon="more_vert"
                class="tabletopicon">
                <q-tooltip >
                  <!-- 컬럼설정 -->
                  {{$label('LBL0000572')}}
                </q-tooltip>
                <q-menu
                  transition-show="jump-down"
                  transition-hide="jump-up"
                >
                  <q-list dense class="configColumnSet">
                    <q-item
                      v-for="(column, idx) in columnsControl"
                      :key="idx"
                      tag="label"
                      v-ripple
                      :disable="column.disabled">
                      <q-item-section side top>
                        <q-checkbox v-if="column.disabled" class="tableCheckBox" :disable="true" color="orange-custom" :value="true"></q-checkbox>
                        <q-checkbox v-else class="tableCheckBox" color="orange-custom" v-model="column.check" v-on:click.native="colChange(column)"></q-checkbox>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{$label(column.label)}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </div>
          <slot name="customTableTitleEditor"></slot>
        </template>
      </template>

      <template v-slot:header="props" v-if="viewHeaders && viewHeaders.length > 0">
        <q-tr
          v-for="(viewHeader, idx) in viewHeaders"
          :key="idx"
          :ref="'ctr'">
          <template v-if="selection!=='none' && idx===0 && editable">
            <q-th auto-width class="text-center" :rowspan="colDepth"
              :class="fixFlag ? 'checkbox-th-fix' : ''"
              style="min-width: 24px; max-width: 24px;">
              <q-checkbox
                v-if="selection==='multiple'"
                class="tableCheckBox"
                color="orange-custom"
                :disable="!editable"
                dense
                v-model="props.selected" />
            </q-th>
          </template>
          <q-th v-if="isExpand && idx===0" auto-width class="text-center" :rowspan="colDepth">
            <slot name="expand-header"></slot>
          </q-th>
          <slot name="perTh" v-bind="props"></slot>
          <template v-for="(col) in viewHeader">
            <q-th
              v-if="col.colspan === 1 ? (idx+1)===col.level : true"
              :auto-width="false"
              :key="col.name"
              class="text-center"
              :colspan="col.colspan"
              :rowspan="col.colspan === 1 && !col.hasOwnProperty('child') ? (colDepth - idx) : 1"
              :props="$_.findIndex(arrangColumns, { name: col.name }) > -1 ? props : void 0"
              :style="setHeaderStyle(col)"
            >
              <span v-html="$comm.getLangLabel(col.label)"></span>
              <font v-if="col.required" color="red">*</font>
              <span v-if="col.helpcomment!=='' && col.helpcomment!==null && col.helpcomment!==undefined">
                <q-btn-group outline >
                  <q-btn
                    icon="help"
                    color="deep-purple-6"
                    text-color="white"
                    class="inner-help-btn"
                    align="center"
                    round>
                    <q-tooltip anchor="bottom left" self="top left">
                      <div class="tooltipCustomTop">
                        <!-- 설명 -->
                        {{$label('LBL0000574')}}
                      </div>
                      <div class="tooltipCustom">
                        <div class="q-pl-xs" style="text-align: left" v-html="col.helpcomment">
                        </div>
                      </div>
                    </q-tooltip>
                  </q-btn>
                </q-btn-group>
              </span>
              <div v-if="col.setHeader && col.type && editable">
                <template v-if="col.type==='select'">
                  <!-- select -->
                  <c-select
                    stype="tableHeaderselect"
                    :editable="editable"
                    :comboItems="col.comboItems"
                    :type="!col.none ? 'allEdit' : ''"
                    :itemText="col.itemText ? col.itemText : 'codeName'"
                    :itemValue="col.itemValue ? col.itemValue : 'code'"
                    @datachange="val => headerDataChange(val.value, props, col)" />
                </template>
                <template v-else-if="col.type==='check'">
                  <q-checkbox
                    dense
                    color="orange-custom"
                    class="tableCheckBox"
                    :disable="!editable"
                    :true-value="col.true ? col.true : 'O'"
                    :false-value="col.false ? col.false : 'X'"
                    v-model="col.value"
                    @input="val => headerDataChange(val, props, col)">
                  </q-checkbox>
                </template>
                <!-- date -->
                <template v-else-if="col.type==='date'">
                  <c-datepicker
                    dense
                    class="tableDatepicker"
                    type="date"
                    :disable="!editable"
                    :range="col.range === true ? true : false"
                    v-model="col.value"
                    @input="val => headerDataChange(val, props, col)"
                  ></c-datepicker>
                </template>
                <!-- text -->
                <template v-else-if="col.type==='text'">
                  <q-input
                    dense
                    v-if="col.type==='text'"
                    v-model="col.value"
                    class="tableHeaderText"
                    @input="val => headerDataChange(val, props, col)"
                  ></q-input>
                </template>
                <template v-else-if="col.type==='number'">
                  <q-input
                    dense
                    v-if="col.type==='number'"
                    v-model="col.value"
                    type="number"
                    class="tableHeaderText"
                    @input="val => headerDataChange(val, props, col)"
                  ></q-input>
                </template>
                <template v-else-if="col.type==='custom'">
                  <c-select
                    v-if="col.headType === 'select'"
                    stype="tableHeaderselect"
                    :editable="editable"
                    :comboItems="col.comboItems"
                    :type="!col.none ? 'allEdit' : ''"
                    :itemText="col.itemText ? col.itemText : 'codeName'"
                    :itemValue="col.itemValue ? col.itemValue : 'code'"
                    @datachange="val => headerDataChange(val.value, props, col)" />
                  <q-btn
                    v-if="col.headType === 'link'"
                    class="tableinnerBtn"
                    :class="{'tableinnerBtn-left': col.align === 'left' ? true : false,  'tableinnerBtn-right': col.align === 'right' ? true : false}"
                    flat
                    :align="col.align"
                    :size="col.size"
                    color="blue-6"
                    icon="build"
                    @click.stop="headLinkClick(props, col)">
                  </q-btn>
                  <q-input
                    dense
                    v-if="col.headType==='text'"
                    v-model="col.value"
                    type="text"
                    class="tableHeaderText"
                    @input="val => headerDataChange(val, props, col)"
                    >
                  </q-input>
                </template>
              </div>
            </q-th>
          </template>
          <slot name="sufTh" v-bind="props"></slot>
        </q-tr>
      </template>

      <!-- 데이터 영역 -->
      <template v-slot:body="props">
        <q-tr
          :props="props"
          ref="compo-tr"
          v-on:click="(e) => rowClick(e, props.row, props.rowIndex)"
          :class="[{ 'bg-light-blue-1': selectedRow(props), 'no-hover-row': noHoverRow, }, customTrClass(props)]">
          <!--
            columns에 정의한 col이 아닌 custom을 위한 영역
          -->
          <q-td v-if="selection!=='none' && editable && isCreate(props.row, -1, props.rowIndex)" auto-width class="text-center"
            :rowspan="getRowspan(-1, props.rowIndex)"
            :class="fixFlag ? 'checkbox-td' : ''"
            style="min-width: 24px; max-width: 24px;">
            <q-checkbox
              class="tableCheckBox"
              color="orange-custom"
              :disable="!editable || props.row[checkDisableColumn]"
              dense
              v-model="props.selected"/>
          </q-td>
          <q-td v-if="isExpand" auto-width class="text-center">
            <q-btn size="xs" class="tableInnerIsExpend" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'"></q-btn>
          </q-td>
          <slot name="perTd" v-bind="props"></slot>
          <template v-for="(col, colIndex) in props.cols">
            <q-td
              v-if="isCreate(props.row, colIndex, props.rowIndex)"
              :ref="'custom-td-' + props.rowIndex + '-' + colIndex"
              :auto-width="false"
              :key="col.name"
              :props="props"
              :class="[(checkEnable(col, props.row, props) ? '' : `edittd-${col.type}`)]"
              :style="tdStyle(col)"
              :rowspan="(getRowspan(colIndex, props.rowIndex))">
              <!-- @click="tdClick(props, col, $event)"
              @contextmenu.capture.prevent="tdRightClick(props, col)" -->
              <template v-if="contentsField(props, col)">
                <!-- default 영역 -->
                <span v-if="check(col.type)"
                  :class="[(checkEnable(col, props.row, props) ? 'non-edit' : ''), (col.innerBtn ? 'descript-span' : ''), (col.color ? 'text-'+ col.color: '')]">
                  <template v-if="colIndex !== 0">
                    {{ props.row[col.name] }}
                  </template>
                  <span
                    v-else
                    :class="{ 'q-tree-link': true, 'q-tree-label': true, }"
                    v-bind:style="setPadding(props.row)">
                    <q-icon
                      :name="iconName(props.row)"
                      :class="{ 'cursor-pointer': (props.row.children && props.row.children.length > 0) }"
                      color="secondary"
                      @click="toggle(props.row, colIndex)"/>
                    {{ props.row[col.name] }}
                  </span>
                  <div v-if="col.description" class="description-td">
                    {{ props.row[col.description] }}
                  </div>
                  <div v-if="col.innerBtn">
                    <q-btn-group outline class="ColumInnerBtnGroup">
                      <template v-for="(btn, idx) in col.btns">
                        <q-btn
                          v-if="editable&&!checkEnable(col, props.row, props)&&!checkInnerBtnEnable(btn, props.row, props)"
                          :key="idx"
                          :label="btn.label"
                          :icon="btn.icon ? btn.icon : void 0"
                          :color="btn.color ? btn.color : 'blue-grey-4'"
                          :text-color="btn.textColor ? btn.textColor : 'white'"
                          class="ColumInnerBtn"
                          align="center"
                          @click.stop="innerBtnClicked(col, props, btn)">
                          <q-tooltip v-if="btn.tooltip">
                            <span v-html="btn.tooltip" />
                          </q-tooltip>
                        </q-btn>
                      </template>
                    </q-btn-group>
                  </div>
                </span>
                <template v-if="editTdCheck(col.type)">
                  <!-- input text -->
                  <template v-if="col.type==='text'">
                    <c-text-column
                      :editable="editable"
                      :disabled="props.row[checkDisableColumn] || checkEnable(col, props.row, props)"
                      :col="col"
                      :props="props"
                      :colorClass="checkColorCloass(col, props.row)"
                      @datachange="datachange(props, col)"
                    />
                  </template>
                  <template v-else-if="col.type==='textarea'">
                    <!-- input textarea -->
                    <c-textarea-column
                      :editable="editable"
                      :disabled="props.row[checkDisableColumn] || checkEnable(col, props.row, props)"
                      :col="col"
                      :props="props"
                      @datachange="datachange(props, col)"
                    />
                  </template>
                  <template v-else-if="col.type==='select'">
                    <!-- select -->
                    <c-select
                      stype="tableselect"
                      :editable="editable"
                      :disabled="!editable || props.row[checkDisableColumn] || checkEnable(col, props.row, props)"
                      :isChip="col.isChip"
                      :comboItems="col.comboItems"
                      :type="!col.none ? 'edit' : ''"
                      :itemText="col.itemText ? col.itemText : 'codeName'"
                      :itemValue="col.itemValue ? col.itemValue : 'code'"
                      v-model="props.row[col.name]"
                      @datachange="val => changeSelect(val, props, col)" />
                  </template>
                  <!-- date -->
                  <template v-else-if="col.type==='date'">
                    <c-datepicker
                      dense
                      class="tableDatepicker"
                      :type="col.dateType ? col.dateType : 'date'"
                      :disabled="checkEnable(col, props.row, props) || !editable || props.row[checkDisableColumn]"
                      :range="col.range === true ? true : false"
                      :minuteStep="col.minuteStep ? col.minuteStep : 1"
                      :start="col.start ? col.start : ''"
                      :end="col.end ? col.end : ''"
                      v-model="props.row[col.name]"
                      @datachange="val => datachange(props, col)"
                    ></c-datepicker>
                  </template>
                  <template v-else-if="col.type==='number'">
                    <!-- input number -->
                    <c-number-column
                      :editable="editable"
                      :disabled="props.row[checkDisableColumn] || checkEnable(col, props.row, props)"
                      :col="col"
                      :props="props"
                      :colorClass="checkColorCloass(col, props.row)"
                      @datachange="datachange(props, col)"
                    />
                  </template>
                  <!-- check box (1) -->
                  <template v-else-if="col.type==='check'">
                    <q-checkbox
                      dense
                      color="orange-custom"
                      class="tableCheckBox"
                      :disable="checkEnable(col, props.row, props) || !editable"
                      :true-value="col.true ? col.true : 'O'"
                      :false-value="col.false ? col.false : 'X'"
                      v-model="props.row[col.name]"
                      @input="val => datachange(props, col)">
                    </q-checkbox>
                  </template>
                  <!-- datetime -->
                  <template v-else-if="col.type==='datetime'">
                    <c-datepicker
                      dense
                      class="tableDatepicker"
                      :type="col.datetimeType ? col.datetimeType : 'time'"
                      :timePickerOptions="setTimePickerOptions(props, col)"
                      :range="col.range === true ? true : false"
                      :disabled="checkEnable(col, props.row, props) || !editable || props.row[checkDisableColumn]"
                      :minuteStep="col.minuteStep ? col.minuteStep : 1"
                      v-model="props.row[col.name]"
                      @datachange="val => datachange(props, col)"
                    ></c-datepicker>
                  </template>
                  <c-multi-select
                    stype="tableselect"
                    v-else-if="col.type==='multiSelect'"
                    :editable="editable"
                    :disabled="checkEnable(col, props.row, props)"
                    :isObject="col.isObject"
                    :valueText="col.valueText ? col.valueText : 'codeName'"
                    :valueKey="col.valueKey ? col.valueKey : 'code'"
                    :comboItems="col.comboItems"
                    :itemText="col.itemText ? col.itemText : 'codeName'"
                    :itemValue="col.itemValue ? col.itemValue : 'code'"
                    v-model="props.row[col.name]"
                    @datachange="val => datachange(props, col)">
                  </c-multi-select>
                </template>
                <template v-else>
                  <!-- text 클릭 -->
                  <template v-if="col.type==='link'">
                    <q-btn
                      v-if="props.row[col.name]"
                      class="tableinnerBtn"
                      :class="{'tableinnerBtn-left': col.align === 'left' ? true : false,  'tableinnerBtn-right': col.align === 'right' ? true : false}"
                      flat
                      :align="col.align"
                      color="blue-6"
                      :label="props.row[col.name]"
                      @click.stop="linkClick(props, col)" />
                  </template>
                  <!-- tag -->
                  <template v-else-if="col.type==='tag'">
                    <q-chip
                      v-if="props.row[col.name]"
                      outline square
                      :color="setTagColor(col, props.row[col.name])"
                      :text-color="col.colorItems ? 'white' : 'black'" >
                      {{setTagName(col, props.row[col.name])}}
                    </q-chip>
                    <template v-else>
                    </template>
                  </template>
                  <!-- custom -->
                  <template v-else-if="col.type==='custom'">
                    <slot name="customArea" v-bind:props="props" v-bind:col="col"></slot>
                  </template>
                  <!-- 천단위 콤마 -->
                  <span v-else-if="col.type==='cost'">
                    {{ props.row[col.name] | toThousandFilter }}
                  </span>
                  <!-- HTML태그허용 -->
                  <span v-else-if="col.type==='html'" v-html="$comm.convertEnter(props.row[col.name])">
                  </span>
                  <!-- badge -->
                  <span v-else-if="col.type==='badge'">
                    <q-badge :color="col.color" :label="props.row[col.name]" />
                  </span>
                  <!-- popup proxy -->
                  <q-btn
                    v-else-if="col.type==='proxy'"
                    class="tableinnerBtn"
                    flat
                    :align="col.align"
                    color="blue-6"
                    :label="props.row[col.name] ? props.row[col.name] : 'Click'">
                    <q-popup-proxy :ref="'proxy_' + props.rowIndex + '_' + col.name" :breakpoint="1000">
                      <component
                        :is="col.component"
                        :props="props"
                        :col="col"
                        :editable="editable"
                        @callback="(data, color) => callbackProxy(data, color, props, col)"
                      />
                    </q-popup-proxy>
                  </q-btn>
                </template>
              </template>
            </q-td>
          </template>
          <!--
            뒤에 배치됨
            columns에 정의한 col이 아닌 custom을 위한 영역
          -->
          <slot name="sufTd" v-bind="props"></slot>
        </q-tr>
        <q-tr v-if="showDescription" :props="props" :key="`e_${props.rowIndex}`" class="q-virtual-scroll--with-prev description-tr">
          <q-td colspan="100%" class="description-td">
            <slot name="description-td" v-bind="props"></slot>
          </q-td>
        </q-tr>
        <q-tr v-if="props.expand" :props="props">
          <q-td colspan="100%">
            <slot name="expand-td" v-bind="props">
            </slot>
          </q-td>
        </q-tr>
      </template>

      <!-- 바텀 영역 -->
      <template v-slot:bottom>
        <!-- 총 건수 -->
        {{$label('LBL0000575')}} : {{data ? data.length : 0}}
        <q-space />

        <!-- 페이징 영역 -->
        <template v-if="usePaging">
          <!-- 페이지 input 영역 -->
          <q-select
            outlined dense
            style="min-width: 50px;max-width: 70px;"
            :options="pageItems"
            color="teal"
            class="tablePagingInput"
            v-model="pagePerRow.pageNumber"
            @input="changePageNumber" />
          <!-- 페이지네이션 영역 -->
          <q-pagination
            v-model="initialPagination.page"
            :max="pagesNumber"
            :max-pages="5"
            class="tablePagingBtn"
            direction-links
            boundary-links
            icon-first="skip_previous"
            icon-last="skip_next"
            color="teal-5"
          />
        </template>
      </template>
    </q-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import arrayToTree from 'array-to-tree';
import XLSX from 'xlsx'
import mixinCommon from './js/mixin-common'
export default {
  /* attributes: name, components, props, data */
  name: 'c-tree-table',
  mixins: [mixinCommon],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    merge: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
    separator: {
      type: String,
      default: 'cell', // horizontal, vertical, cell, none
    },
    selection: {
      type: String,
      default: 'none', // multiple, single, none
    },
    rowKey: {
      type: String,
      default: 'name',
    },
    columnSetting: {
      type: Boolean,
      default: true,
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
    isExcelDown: {
      type: Boolean,
      default: true,
    },
    filtering: {
      type: Boolean,
      default: true,
    },
    cardClass: {
      type: String,
      default: '', // bg-primary text-white
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    autoFullHeight: {
      type: Boolean,
      default: true,
    },
    gridHeight: {
      type: String,
      default: '',
    },
    usePaging: {
      type: Boolean,
      default: true,
    },
    isDashboard: {
      type: Boolean,
      default: false,
    },
    hideBottom: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    isTop: {
      type: Boolean,
      default: true,
    },
    tableId: {
      type: String,
      default: '',
    },
    parentProperty: {
      type: String,
      default: '',
    },
    customID: {
      type: String,
      default: '',
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    /* eslint-disable no-unused-vars */
    isSelection: {
      type: Function,
      default: function(props) {
        return true;
      },
    },
    topBorderClass: {
      type: String,
      default: '',
    },
    contentsField: {
      type: Function,
      default: function(props, col) {
        return true;
      },
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    noHighLight: {
      type: Boolean,
      default: false,
    },
    noHoverRow: {
      type: Boolean,
      default: false,
    },
    checkDisableColumn: {
      type: String,
      default: 'disable',
    },
    checkClickFlag: {
      type: Boolean,
      default: true,
    },
    tableTimePickerOptions: {
      type: Object,
    },
    customTrClass: {
      type: Function,
      default: function(props) {
        return '';
      },
    },
    pagePerRow: {
      type: Object,
      default: function() {
        return {
          pageNumber: 30,
        }
      },
    },
  },
  data() {
    return {
      fullscreen: false,
      height: '100%',
      selected: [],
      filter: '',
      colDepth: 1,
      childLength: [],
      viewHeaders: [],
      arrangColumns: [],
      columnsControl: [],
      visibleColumns: [],
      hiddenColumns: [],
      initialPagination: null,
      pageItems: [20, 30, 50, 100],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      delay: 180,
      clicks: 0,
      timer: null,
      toggleFlag: null,
      selectedRowIndex: -1,
      render: {
        from: 0,
        to: 0,
      },
      change: {
        data: ''
      },
      isExpanded: true,
      itemId: null,
      isInit: true,
    };
  },
  computed: {
    pagesNumber () {
      let _length = this.data.length
      if (this.filter) {
        _length = this.$refs['compo-table'].filteredSortedRowsNumber
      }
      return Math.ceil(_length / this.initialPagination.rowsPerPage)
    },
    convertTreeData() {
      if (!this.data || this.data.length === 0) return;
      return arrayToTree(this.data, {
        parentProperty: this.parentProperty,
        customID: this.customID,
      });
    },
    arrayTreeObj() {
      var newObj = [];
      this.recursive(this.convertTreeData, newObj, 0, this.itemId, this.isExpanded, this.isInit);
      return newObj;
    },
    isEditing() {
      let val = false;
      let whitList = ['html', 'cost', 'link', 'tag', 'custom', 'proxy','img', 'badge']
      if (this.arrangColumns && this.arrangColumns.length > 0) {
        this.$_.forEach(this.arrangColumns, col => {
          if (col.type && (this.$_.indexOf(whitList, col.type) === -1)) {
            val = true;
            return false;
          }
        })
      }
      return val
    },
    fixFlag() {
      return this.arrangColumns && this.arrangColumns.length > 0 ? this.arrangColumns[0].fix : false
    },
    noDataLabel() {
      return this.$comm.getLangMessage('MSGNODATA') // 데이터가 없습니다.
    },
    noResultLabel() {
      return this.$comm.getLangMessage('MSGNOFILTERING') // 필터링 된 데이터가 없습니다.
    },
  },
  watch: {
    gridHeight() {
      this.setSize();
    },
    columns: {
      handler: function (c, p) {
        this.setColumnControl();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    window.removeEventListener('resize', this.setSize);
  },
  /* methods */
  methods: {
    init() {
      this.setPaging();
      this.setColumnControl();
      this.setSize();
    },
    setPaging() {
      if (this.usePaging) {
        this.initialPagination = {
          sortBy: 'desc',
          descending: false,
          page: 1,
          rowsPerPage: this.pagePerRow && this.pagePerRow.pageNumber ? 
            Number(this.$_.clone(this.pagePerRow.pageNumber)) : 30
        };
      } else {
        this.initialPagination = {
          sortBy: 'desc',
          descending: false,
          page: 1,
          rowsPerPage: 0
        };
      }
    },
    setColumnControl() {
      this.arrangColumns = [];
      let colDepth = 1;
      this.colDepth = 1;
      this.$_.forEach(this.columns, column => {
        let idx = this.$_.findIndex(this.childLength, { level: 1 });
        if (idx > -1) {
          this.childLength[idx].val = 0;
        } else {
          this.childLength.push({ level: 1, val: 0});
        }
        column.level = 1;
        if (!column.hasOwnProperty('child')) {
          this.arrangColumns.push(column);
          column.colspan = 1;
        } else {
          this.arrangColumns.push.apply(this.arrangColumns, this.FloorColumn(column, 2, false));
          column.colspan = column.child.length + this.$_.find(this.childLength, { level: 1 }).val;

          if (this.colDepth > colDepth) {
            colDepth = this.$_.clone(this.colDepth);
          }
          this.colDepth = 1;
        }
      });

      this.colDepth = colDepth;
      if (this.viewHeaders && this.viewHeaders.length > 0) {
        this.viewHeaders = [];
      }

      (this.viewHeaders = []).length = this.colDepth;
      this.viewHeaders.fill(new Array());

      for (let i = 0; i < this.colDepth; i++) {
        this.viewHeaders[i] = this.getLevelHeaders(this.columns, i + 1);
      }

      this.visibleColumns = this.$_.map(this.arrangColumns, 'name')
      // 컬럼visible정보를 localStorage에 있으면 불러오기..
      let tablevisiblecol = JSON.parse(window.localStorage.getItem(this.title));
      if (tablevisiblecol !== null) {
        this.visibleColumns = tablevisiblecol;
      }
      let index = 0;
      this.columnsControl = [];
      this.$_.forEach(this.arrangColumns, col => {
        this.columnsControl.push({
          label: col.label,
          name: col.name,
          check: (this.visibleColumns.indexOf(col.name) > -1 ? true : false),
          disabled: this.$_.findIndex(this.merge, { index: index }) > -1 || col.level > 1
        });
        index++;
      })
      this.$refs['compo-table'].$el.style.setProperty("--my-var", (this.colDepth * 33) + 'px');
    },
    FloorColumn(column, level, checkDept) {
      if (!checkDept) {
        this.colDepth++;
      }
      let returnCols = [];
      let idx = this.$_.findIndex(this.childLength, { level: level });
      if (idx > -1) {
        this.childLength[idx].val = 0;
      } else {
        this.childLength.push({ level: level, val: 0});
      }
      this.$_.forEach(column.child, childColumn => {
        childColumn.level = level;
        if (!childColumn.hasOwnProperty('child')) {
          returnCols.push(childColumn);
          childColumn.colspan = 1;
        } else {
          let _checkDept = level >= this.colDepth ? false : true
          returnCols.push.apply(returnCols, this.FloorColumn(childColumn, (level + 1), _checkDept));
          childColumn.colspan = childColumn.child.length + this.$_.find(this.childLength, { level: level }).val;
          this.$_.forEach(this.childLength, item => {
            if (level > item.level) {
              item.val += (childColumn.child.length - 1);
            }
          })
        }
      });
      return returnCols;
    },
    getLevelHeaders(columns, level) {
      let returnHeaders = [];
      this.$_.forEach(columns, column => {
        if (column.level === level) {
          returnHeaders.push(column);
        } else {
          if (column.hasOwnProperty('child')) {
            returnHeaders.push.apply(returnHeaders, this.getLevelHeaders(column.child, level));
          }
        }
      });
      return returnHeaders;
    },
    setSize() {
      /**
       * autoFullHeight: 윈도우 사이즈에 맞게 자동으로 높이 조절
       * gridHeight: 그리드의 높이를 지정하는 prop
       *
       * 그리드의 높이를 지정하지 않았으며 autoFullHeight를 true로 준 경우 높이를 윈도우 사이즈에 맞게 조절한다.
       * ※ 단, 자동으로 조절되는 높이가 200보다 작게 설정되지 않는다.
       * */
      if (this.autoFullHeight && !this.gridHeight) {
        let offsettop = this.$refs['compo-table'].$el.getBoundingClientRect().top;
        let topsizes = [111, 208, 214];
        let tempHeight = (window.innerHeight - (this.$refs['compo-table'] ?  offsettop: 250)) - (topsizes.indexOf(offsettop) < 0 ? 51 : 80);
        if (tempHeight < 300) {
          tempHeight = 300;
        }
        this.height = String(tempHeight) + 'px';
      } else if (this.gridHeight) {
        this.height = this.gridHeight;
      } else {
        this.height = 300;
      }
      this.$emit('getTableHeight', this.height)
    },
    check(type) {
      return type ? false : true
    },
    checkEdit(type) {
      let whiteList = ['textarea', 'number'];
      return type && (this.$_.indexOf(whiteList, type) > -1);
    },
    editTdCheck(type) {
      /**
       * type이 주어진 td이면서 editing이 필요한 컬럼인지 확인
       */
      let blackList = ['html', 'badge', 'img', 'cost', 'link', 'tag', 'custom', 'proxy'];
      return (this.$_.indexOf(blackList, type) === -1);
    },
    /** link 클릭 */
    linkClick(props, col) {
      this.$emit('linkClick', props.row, col, props.pageIndex);
    },
    /** 행 클릭 */
    rowClick(event, row, index) {
      if (this.toggleFlag) {
        this.toggleFlag = false;
        return;
      }
      // td 안에 있는 버튼을 클릭 시에는 row Click 이벤트가 먹히지 않도록 설정
      if (this.isInnerBtnClick) return;
      this.clicks++
      this.selectedRowIndex = index;
      if(this.clicks === 1) {
        var self = this
        this.timer = setTimeout(function() {
          /** 행 클릭 */
          self.$emit('rowClick', row, index);
          self.clicks = 0
        }, this.delay);
      } else{
        /** 행 더블 클릭 */
        clearTimeout(this.timer);
        this.$emit('rowDblclick', row, index);
        this.clicks = 0;
      }
    },
    selectedRow(props) {
      if (!this.noHighLight) {
        return props.rowIndex === this.selectedRowIndex;
      } else {
        return false;
      }
    },
    /** 체크박스 체크/체크해제 */
    changeSelection(detail) {
      if (detail.added) {
        let noneSelected = this.$_.filter(detail.rows, row => {
          return Boolean(row[this.checkDisableColumn])
        })
        setTimeout(() => {
          this.$_.forEach(noneSelected, item => {
            let idx = this.$_.findIndex(this.selected, sel => {
              return sel[this.rowKey] === item[this.rowKey]
            })
            if (idx > -1) {
              this.selected.splice(idx, 1)
            }
          })
        }, 100);
      }
      this.$emit('changeSelection', detail);
    },
    getSelected() {
      this.$emit('getSelected', this.selected);
    },
    virtualScroll(details) {
      this.render = {
        from: details.from,
        to: details.to,
      }
    },
    isCreate(row, colIndex, rowIndex) {
      if (!this.$refs['compo-table']) {
        return false;
      }
      let data = this.$refs['compo-table'].filteredSortedRows;
      let returnVal = true;
      if (this.merge && this.merge.length > 0) {
        let colName = '';
        if (colIndex > -1) {
          this.$_.forEach(this.merge, item => {
            if (item.index === colIndex) {
              colName = item.colName;
              return false;
            }
          });
        } else {
          colName = this.$_.clone(this.rowKey)
        }
        if (colName) {
          let viewRowIndex = rowIndex
          let rows = this.$_.clone(data);
          if (this.initialPagination.rowsPerPage !== 0) {
            viewRowIndex = rowIndex > 0 ? rowIndex % this.initialPagination.rowsPerPage : rowIndex
            let lastPage = this.$_.ceil(data.length / this.initialPagination.rowsPerPage);
            let stSliceIdx = (this.initialPagination.page - 1) * this.initialPagination.rowsPerPage;
            if (lastPage !== this.initialPagination.page) {
              // 현재 보는 페이지가 마지막 페이지가 아닌경우
              let edSliceIdx = data.length - (this.initialPagination.page * this.initialPagination.rowsPerPage);
              rows = this.$_.drop(rows, stSliceIdx);
              rows = this.$_.dropRight(rows, edSliceIdx);
            } else {
              // 현재 보는 페이지가 마지막 페이지인 경우
              rows = this.$_.drop(rows, stSliceIdx);
            }
          } else {
            // 페이징을 사용하지 않는 경우 100 단위로 랜더링 됨으로 100을 기준으로 row를 정리
            viewRowIndex = rowIndex > 0 ? rowIndex - this.render.from : rowIndex
            rows = this.$_.drop(rows, this.render.from);
            rows = this.$_.dropRight(rows, data.length - this.render.to - 1);
          }

          if (rows && rows.length > 0 && viewRowIndex > 0 && rows[viewRowIndex]) {
            if (rows[viewRowIndex - 1] && rows[viewRowIndex - 1][colName] === rows[viewRowIndex][colName]) {
              returnVal = false;
            }
          }
        }
      }

      return returnVal;
    },
    getRowspan(colIndex, rowIndex) {
      let data = this.$refs['compo-table'].filteredSortedRows;
      let rowSpan = 0;
      if (this.merge && this.merge.length > 0) {
        let colName = '';
        if (colIndex > -1) {
          this.$_.forEach(this.merge, item => {
            if (item.index === colIndex) {
              colName = item.colName;
              return false;
            }
          });
        } else {
          colName = this.$_.clone(this.rowKey)
        }
        if (colName) {
          let viewRowIndex = rowIndex
          let rows = this.$_.clone(data);
          if (this.initialPagination.rowsPerPage !== 0) {
            let lastPage = this.$_.ceil(data.length / this.initialPagination.rowsPerPage);
            let stSliceIdx = (this.initialPagination.page - 1) * this.initialPagination.rowsPerPage;
            viewRowIndex = rowIndex > 0 ? rowIndex % this.initialPagination.rowsPerPage : rowIndex
            if (lastPage !== this.initialPagination.page) {
              // 현재 보는 페이지가 마지막 페이지가 아닌경우
              let edSliceIdx = data.length - (this.initialPagination.page * this.initialPagination.rowsPerPage);
              rows = this.$_.drop(rows, stSliceIdx);
              rows = this.$_.dropRight(rows, edSliceIdx);
            } else {
              // 현재 보는 페이지가 마지막 페이지인 경우
              rows = this.$_.drop(rows, stSliceIdx);
            }
          } else {
            // 페이징을 사용하지 않는 경우 100 단위로 랜더링 됨으로 100을 기준으로 row를 정리
            viewRowIndex = rowIndex > 0 ? rowIndex - this.render.from : rowIndex
            rows = this.$_.drop(rows, this.render.from);
            rows = this.$_.dropRight(rows, data.length - this.render.to - 1);
          }
          if (rows && rows.length > 0 && rows[viewRowIndex]) {
            let postName = rows[viewRowIndex][colName];
            for (let i = viewRowIndex; i < rows.length; i++) {
              if (postName === rows[i][colName]) {
                rowSpan++;
              } else {
                break;
              }
            }
            for (let i = 0; i < viewRowIndex; i++) {
              if (postName === rows[i][colName]) {
                rowSpan++;
              } else {
                break;
              }
            }
          }
        }
      }

      if (rowSpan === 0) {
        rowSpan = 1;
      }

      return rowSpan > 1 ? rowSpan : void 0;
    },
    changePageNumber() {
      this.initialPagination.rowsPerPage = Number(this.$_.clone(this.pagePerRow.pageNumber))
    },
    colChange(column) {
      if (!column.disabled) {
        if (column.check) {
          this.visibleColumns.push(column.name);
          let index = this.$_.indexOf(this.hiddenColumns, column.name);
          if (index > -1) {
            this.hiddenColumns.splice(index, 1)
          }
        } else {
          let index = this.$_.indexOf(this.visibleColumns, column.name);
          this.visibleColumns.splice(index, 1)
          this.hiddenColumns.push(column.name)
        }
        // 보이는 컬럼이 전체일경우 localStorage 삭제, 그외 컬럼visible정보를 localStorage에 저장
        if (this.visibleColumns.length == this.arrangColumns.length) {
          window.localStorage.removeItem(this.title);
        } else {
          window.localStorage.setItem(this.title, JSON.stringify(this.visibleColumns));
        }
      }
    },
    checkInnerBtnEnable(btn, row, props) {
      let returnVal = false;
      if (btn.disableTarget) {
        if (btn.disableCon) {
          returnVal = row[btn.disableTarget] !== btn.disableCon;
        } else {
          returnVal = !row[btn.disableTarget]
        }
      }
      return returnVal;
    },
    checkEnable(col, row, props) {
      let returnVal = false;
      if (col.disableTarget) {
        returnVal = row[col.disableTarget] !== col.disableCon;
      }

      if (this.selection !== 'none' && col.selectionAble) {
        returnVal = !props.selected;
      }
      return returnVal;
    },
    checkColorCloass(col, row) {
      let returnColorClass = '';
      if (col.colorTarget) {
        let _classes = col.colorClass;
        this.$_.forEach(_classes, item => {
          if (item.split(',')[0] === row[col.colorTarget]) {
            returnColorClass = item.split(',')[1];
            return false;
          }
        });
      }
      return returnColorClass;
    },
    headerDataChange(val, props, col) {
      if (this.data && this.data.length > 0) {
        this.$_.forEach(this.data, item => {
          if (!item[this.checkDisableColumn] && !(col.disableTarget && item[col.disableTarget] !== col.disableCon)) {
            item[col.name] = val
            if (item['editFlag'] !== 'C') {
              item['editFlag'] = 'U'
              item['chgUserId'] = this.$store.getters.user.userId
            }
          }
        })
        // 해당 경우에는 table-data-change emit을 날리지 않음
        this.$emit('headerDataChange', props, col)
      } 
    },
    headLinkClick(props, col) {
      this.$emit('headLinkClick', props, col, props.pageIndex);
    },
    datachange(props, col) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
      this.$emit('table-data-change', props, col)
    },
    showPopupEdit(props, col) {
      if (col.type === 'text') {
        this.$refs['popup-edit-text_' + props.rowIndex + '_' + col.name][0].focus();
        this.$refs['popup-edit-text_' + props.rowIndex + '_' + col.name][0].select();
      } else if (col.type === 'textarea') {
        this.$refs['popup-edit-textarea_' + props.rowIndex + '_' + col.name][0].focus();
        this.$refs['popup-edit-textarea_' + props.rowIndex + '_' + col.name][0].select();
      }
    },
    changeSelect(val, props, col) {
      props.row[col.name] = val.value;
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
      this.$emit('table-data-change', props, col)
    },
    clickFullScreen() {
      if (this.fullscreen === false) {
        this.fullscreen = true;
        this.height = '100%';
      } else {
        this.fullscreen = false;
        setTimeout(() => {
          this.setSize();
        }, 100);
      }
    },
    innerBtnClicked(col, props, btn) {
      this.isInnerBtnClick = true;
      setTimeout(() => {
        this.isInnerBtnClick = false
      }, 100);
      this.$emit('innerBtnClicked', col, props, btn);
    },
    callbackProxy(data, color, props, col) {
      let refName = 'proxy_' + props.rowIndex + '_' + col.name;
      this.$emit('callbackProxy', data, props, col);
      this.$refs[refName][0].hide();
    },
    setHeaderClass(col) {
      let returnVal = 'text-center ' + (col.hasOwnProperty('headerClass') ? col.headerClass : '')
      return returnVal;
    },
    setTagColor(col, data) {
      if (col.colorItems) {
        let color = this.$_.find(col.colorItems, { code: data }) || this.$_.find(col.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(col, data) {
      if (col.colorItems) {
        let color = this.$_.find(col.colorItems, { code: data }) || this.$_.find(col.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    setHeaderStyle(col) {
      let width = col.style ? this.$_.trim(this.$_.split(col.style, ':')[1]) : '';
      let returnText = width ? 'min-width: ' + width + ';max-width: ' + width : '';

        // + ';max-width: ' + width : '';
      if (returnText !== '') {
        returnText += ';white-space: normal';
      }
      if (col.headerColor) {
        returnText += ';background: ' + col.headerColor;
      }
      if (col.fix && col.style) {
        /**
         * fix를 하기 위해서는 width가 있어야함
         */
        let left = 0;
        if (this.selection!=='none' && this.editable) {
          /**
           * 체크박스가 있는 경우 clientLeft
           */
          left = 24
        }
        let colIdx = this.$_.findIndex(this.arrangColumns, { name: col.name })
        if (colIdx > 0) {
          for (let i = 0; i < colIdx; i++) {
            let _left = this.$_.trim(this.arrangColumns[i].style.replace(/width[:]|px/g, ''))
            left += _left && !isNaN(_left) ? Number(_left) : 0
          }
        }
        returnText += ';position: sticky;background-color: #BCAAA4;z-index: 3;left:' + left + 'px;';
      }
      return returnText;
    },
    downloadExcel() { // 그리드 엑셀 다운로드 (멀티 haeder는 가장 아래 header기준 (3레벨 header까지))
      let columnsControlData = this.data.slice();

      if (this.data.length > 0) {
        var sheetheaderfileds = [];
        var sheetheadertitle = {};
        let colsContr = this.visibleColumns;
        this.columns.forEach(function(value) {
          if(value.child) {
            value.child.forEach(function(value2) {
              if(value2.child) {
                value2.child.forEach(function(value3) {
                  if (colsContr.indexOf(value3.field) > -1) {
                    sheetheaderfileds.push(value3.field);
                    sheetheadertitle[value3.field] = value3.label;
                  }
                });
              } else {
                if (colsContr.indexOf(value2.field) > -1) {
                  sheetheaderfileds.push(value2.field);
                  sheetheadertitle[value2.field] = value2.label;
                }
              }
            });
          } else {
            if (colsContr.indexOf(value.field) > -1) {
              sheetheaderfileds.push(value.field);
              sheetheadertitle[value.field] = value.label;
            }
          }
        });
        let printData = [];
        columnsControlData.forEach(function(value) {
          let keys = Object.keys(value);
          let obj = {};
          keys.forEach(function(_value) {
            if (sheetheaderfileds.map(function(e) {return e;}).indexOf(_value) > -1) {
              obj[_value] = value[_value];
            }
          })
          printData.push(obj)
        });
        const jsonWorkSheet = XLSX.utils.json_to_sheet(
          printData,
          {
            header: sheetheaderfileds,
          }
        )
        const range = XLSX.utils.decode_range(jsonWorkSheet['!ref'])
        for(let c = range.s.c; c <= range.e.c; c++) {
          const header = XLSX.utils.encode_col(c) + '1'
          jsonWorkSheet[header].v = sheetheadertitle[ jsonWorkSheet[header].v ]
        }
        const workBook = {
          SheetNames: ['Sheet1'], // sheet name
          Sheets: {
            'Sheet1': jsonWorkSheet,
          }
        };
        XLSX.writeFile(workBook, this.title + '-' + this.$comm.moment().format('YYYYMMDD') + '.xlsx');
      }
    },
    setColColor(col) {
      if (col && col.colColor) {
        return col.colColor.bgColor + ' ' + col.colColor.textColor
      } else {
        return '';
      }
    },
    recursive(obj, newObj, level, itemId, isExpend, isInit) {
      if (!obj) return;
      let vm = this;
      obj.forEach(function(o) {
        if (o.children && o.children.length != 0) {
          o.level = level;
          o.leaf = false;
          newObj.push(o);
          if (o[vm.customID] == itemId || (vm.expandAll && isInit)) {
            o.expend = isExpend;
          }
          if (o.expend == true) {
            vm.recursive(o.children, newObj, o.level + 1, itemId, isExpend, isInit);
          }
        } else {
          o.level = level;
          o.leaf = true;
          newObj.push(o);
          return false;
        }
      });

      if (vm.isInit) {
        vm.isInit = false;
      }
    },
    /* eslint-disable no-unused-vars */
    toggle(item, index) {
      if (index === 0) {
        this.toggleFlag = true;
        this.itemId = item[this.customID];

        item.leaf = false;
        //show  sub items after click on + (more)
        if (
          item.leaf == false &&
          item.expend == undefined &&
          item.children != undefined
        ) {
          if (item.children.length != 0) {
            this.recursive(item.children, [], item.level + 1, item[this.customID], true, false);
          }
        }

        if (item.expend == true && item.children != undefined) {
          item.children.forEach(function(o) {
            o.expend = undefined;
          });

          this.$set(item, "expend", undefined);
          this.$set(item, "leaf", false);
          this.itemId = null;
        }
      }
    },
    setPadding(item) {
      return `padding-left: ${item.level * 30}px;`;
    },
    iconName(item) {
      if (item.expend == true) {
        return "remove_circle_outline";
      }
      if (item.children && item.children.length > 0) {
        return "control_point";
      }
      return "done";
    },
    tdClick(props, col, self) {
      if (!this.checkClickFlag) return
      let rowspan = 0;
      let target = self.target
      if (target.localName === 'span') {
        target = target.offsetParent
      }
      rowspan = target.getAttribute('rowspan') ? target.getAttribute('rowspan') : 0;
      /**
       * selection이 있는 경우 실행 selected rowKey
       *
       * selected check
       */
      if (this.check(col.type) || col.type === 'html') {
        if (this.selection === 'multiple') {
          let data = this.$refs['compo-table'].filteredSortedRows;
          let filterData = []
          if (rowspan > 0) {
            filterData = this.$_.filter(data, item => {
              return item[col.name] === props.row[col.name]
            })
          } else {
            filterData = this.$_.filter(data, item => {
              return item[this.rowKey] === props.row[this.rowKey]
            })
          }
          if (filterData && filterData.length > 0) {
            this.$_.forEach(filterData, item => {
              if (this.$_.findIndex(this.selected, selectItem => {
                return selectItem[this.rowKey] === item[this.rowKey]
              }) === -1) {
                this.selected.push(item)
              }
            })
          }
        } else if (this.selection === 'single') {
          let data = this.$refs['compo-table'].filteredSortedRows;
          this.selected = this.$_.filter(data, item => {
            return item[this.rowKey] === props.row[this.rowKey]
          })
        }
      }
    },
    tdRightClick(props, col) {
      if (!this.checkClickFlag) return
      /**
       * selection이 있는 경우 실행 selected rowKey
       *
       * selected uncheck
       */
      if (this.check(col.type)) {
        if (this.selection === 'multiple' || this.selection === 'single') {
          let data = this.$refs['compo-table'].filteredSortedRows;
          let filterData = this.$_.filter(data, item => {
            return item[col.name] === props.row[col.name]
          })
          if (filterData && filterData.length > 0) {
            this.$_.forEach(filterData, item => {
              if (this.$_.findIndex(this.selected, selectItem => {
                return selectItem[this.rowKey] === item[this.rowKey]
              }) > -1) {
                this.selected = this.$_.reject(this.selected, item)
              }
            })
          }
        }
      }

    },
    setTimePickerOptions(props, col) {
      if (col.type === 'datetime'
        && this.tableTimePickerOptions && this.tableTimePickerOptions.start
        && col.name === this.tableTimePickerOptions.colName ) {
        let stepSplit = this.$_.split(this.tableTimePickerOptions.step, ':');
        let curStart = props.rowIndex > 0 ? this.data[props.rowIndex - 1][col.name] : this.tableTimePickerOptions.start
        let start = this.$comm.moment(this.$comm.getToday() + ' ' + curStart).add((Number(stepSplit[1])), 'm').add((Number(stepSplit[0])), 'h').format('HH:mm');
        return {
          start: start,
          step: this.tableTimePickerOptions.step,
          end: this.tableTimePickerOptions.end
        }
      } else {
        return undefined;
      }
    },
    tdStyle(col) {
      let text = '';
      if (col.innerBtn && col.btns) {
        text = 'padding-top:10px !important;'
      }
      if (col.color) {
        text += 'font-weight:800; color:' + col.color + ';'
      }
      if (col.currentDay) {
        text += 'background-color: #eaffdd;'
      }
      if (col.fix && col.style) {
        /**
         * fix를 하기 위해서는 width가 있어야함
         */
        let left = 0;
        if (this.selection!=='none' && this.editable) {
          /**
           * 체크박스가 있는 경우 clientLeft
           */
          left = 24
        }
        let colIdx = this.$_.findIndex(this.arrangColumns, { name: col.name })
        if (colIdx > 0) {
          for (let i = 0; i < colIdx; i++) {
            let _left = this.$_.trim(this.arrangColumns[i].style.replace(/width[:]|px/g, ''))
            left += _left && !isNaN(_left) ? Number(_left) : 0
          }
        }
        text += ';position: sticky;background-color: #EFEBE9;z-index: 1;left:' + left + 'px;';
      }
      return text;
    }
  }
};
</script>
<style scoped>
.default-table tbody .q-tr [rowspan] {
  vertical-align: top;
}
@supports (position: sticky) or (position: -webkit-sticky) {
  .default-table tbody .q-tr [rowspan]:not(.edittd-text):not(.edittd-textarea):not(.edittd-number):not(.edittd-date):not(.edittd-select) > span:not(.descript-span) {
    position: sticky;
    top: calc(var(--my-var) + 2px);
  }
}
</style>