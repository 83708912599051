var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vsm-item",
      class: [
        { "first-item": _vm.firstItem },
        { "mobile-item": _vm.mobileItem },
        { "open-item": _vm.show },
        { "active-item": _vm.active },
        { "parent-active-item": _vm.childActive },
      ],
      on: {
        mouseenter: function ($event) {
          return _vm.mouseEnter($event)
        },
      },
    },
    [
      _vm.isRouterLink
        ? [
            _c(
              "router-link",
              _vm._b(
                {
                  staticClass: "vsm-link",
                  class: [
                    _vm.item.class,
                    {
                      vsmlinkexist:
                        _vm.item.href != "" && _vm.item.href !== null,
                    },
                  ],
                  attrs: {
                    to: _vm.item.href,
                    disabled: _vm.item.disabled,
                    tabindex: _vm.item.disabled ? -1 : "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clickEvent.apply(null, arguments)
                    },
                  },
                },
                "router-link",
                _vm.item.attributes,
                false
              ),
              [
                _c(
                  "q-menu",
                  {
                    attrs: {
                      "transition-show": "scale",
                      "transition-hide": "scale",
                      "touch-position": "",
                      "context-menu": "",
                    },
                  },
                  [
                    _c(
                      "q-list",
                      { staticClass: "topTabContext", attrs: { dense: "" } },
                      [
                        _c(
                          "q-item",
                          {
                            directives: [
                              { name: "close-popup", rawName: "v-close-popup" },
                            ],
                            attrs: { clickable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.addFavoriteTab(_vm.item)
                              },
                            },
                          },
                          [
                            _c(
                              "q-item-section",
                              [
                                _c("q-icon", {
                                  staticClass: "text-dark",
                                  attrs: { name: "star" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.setFavoriteLabel(_vm.item)) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "q-item",
                          {
                            directives: [
                              { name: "close-popup", rawName: "v-close-popup" },
                            ],
                            attrs: { clickable: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openNewWindow(_vm.item)
                              },
                            },
                          },
                          [
                            _c(
                              "q-item-section",
                              [
                                _c("q-icon", {
                                  staticClass: "text-dark",
                                  attrs: { name: "launch" },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$label("LBL0000866")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.item.icon
                  ? [
                      typeof _vm.item.icon === "string" ||
                      _vm.item.icon instanceof String
                        ? _c(
                            "i",
                            {
                              staticClass: "vsm-icon material-icons",
                              class: _vm.item.icon,
                            },
                            [_vm._v(_vm._s(_vm.item.icon))]
                          )
                        : _c(
                            _vm.item.icon.element ? _vm.item.icon.element : "i",
                            _vm._b(
                              {
                                tag: "component",
                                staticClass: "vsm-icon material-icons",
                                class: _vm.item.icon.class,
                              },
                              "component",
                              _vm.item.icon.attributes,
                              false
                            ),
                            [_vm._v(" " + _vm._s(_vm.item.icon.text) + " ")]
                          ),
                    ]
                  : _vm._e(),
                !_vm.isCollapsed || _vm.mobileItem
                  ? [
                      _vm.item.badge
                        ? _c(
                            _vm.item.badge.element
                              ? _vm.item.badge.element
                              : "span",
                            _vm._b(
                              {
                                tag: "component",
                                staticClass: "vsm-badge",
                                class: _vm.item.badge.class,
                                style: [
                                  _vm.rtl
                                    ? _vm.item.child
                                      ? { "margin-left": "30px" }
                                      : ""
                                    : _vm.item.child
                                    ? { "margin-right": "30px" }
                                    : "",
                                ],
                              },
                              "component",
                              _vm.item.badge.attributes,
                              false
                            ),
                            [_vm._v(" " + _vm._s(_vm.item.badge.text) + " ")]
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "vsm-title" }, [
                        _vm._v(_vm._s(_vm.item.title)),
                      ]),
                      _vm.item.child
                        ? _c(
                            "div",
                            {
                              staticClass: "vsm-arrow",
                              class: [
                                { "open-arrow": _vm.show },
                                { "slot-icon": _vm.$slots["dropdown-icon"] },
                              ],
                            },
                            [_vm._t("dropdown-icon")],
                            2
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        : [
            _c(
              "a",
              _vm._b(
                {
                  staticClass: "vsm-link",
                  class: [
                    _vm.item.class,
                    { vsmlinkexist: _vm.item.href != "" },
                  ],
                  attrs: {
                    href: _vm.item.href ? _vm.item.href : "#",
                    disabled: _vm.item.disabled,
                    tabindex: _vm.item.disabled ? -1 : "",
                  },
                  on: { click: _vm.clickEvent },
                },
                "a",
                _vm.item.attributes,
                false
              ),
              [
                _vm.item.icon
                  ? [
                      typeof _vm.item.icon === "string" ||
                      _vm.item.icon instanceof String
                        ? _c(
                            "i",
                            {
                              staticClass: "vsm-icon material-icons",
                              class: _vm.item.icon,
                            },
                            [_vm._v(_vm._s(_vm.item.icon))]
                          )
                        : _c(
                            _vm.item.icon.element ? _vm.item.icon.element : "i",
                            _vm._b(
                              {
                                tag: "component",
                                staticClass: "vsm-icon material-icons",
                                class: _vm.item.icon.class,
                              },
                              "component",
                              _vm.item.icon.attributes,
                              false
                            ),
                            [_vm._v(" " + _vm._s(_vm.item.icon.text) + " ")]
                          ),
                    ]
                  : _vm._e(),
                !_vm.isCollapsed || _vm.mobileItem
                  ? [
                      _vm.item.badge
                        ? _c(
                            _vm.item.badge.element
                              ? _vm.item.badge.element
                              : "span",
                            _vm._b(
                              {
                                tag: "component",
                                staticClass: "vsm-badge",
                                class: _vm.item.badge.class,
                                style: [
                                  _vm.rtl
                                    ? _vm.item.child
                                      ? { "margin-left": "30px" }
                                      : ""
                                    : _vm.item.child
                                    ? { "margin-right": "30px" }
                                    : "",
                                ],
                              },
                              "component",
                              _vm.item.badge.attributes,
                              false
                            ),
                            [_vm._v(_vm._s(_vm.item.badge.text))]
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "vsm-title" }, [
                        _vm._v(_vm._s(_vm.item.title)),
                      ]),
                      _vm.item.child
                        ? _c(
                            "div",
                            {
                              staticClass: "vsm-arrow",
                              class: [
                                { "open-arrow": _vm.show },
                                { "slot-icon": _vm.$slots["dropdown-icon"] },
                              ],
                            },
                            [_vm._t("dropdown-icon")],
                            2
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
      _vm.item.child
        ? [
            !_vm.isCollapsed
              ? [
                  _c(
                    "transition",
                    {
                      attrs: { name: "expand" },
                      on: {
                        enter: _vm.expandEnter,
                        afterEnter: _vm.expandAfterEnter,
                        beforeLeave: _vm.expandBeforeLeave,
                      },
                    },
                    [
                      _vm.show
                        ? _c(
                            "div",
                            { staticClass: "vsm-dropdown" },
                            [
                              _c(
                                "listItem",
                                {
                                  attrs: {
                                    items: _vm.item.child,
                                    "show-child": _vm.showChild,
                                    rtl: _vm.rtl,
                                  },
                                },
                                [
                                  _vm._t("dropdown-icon", null, {
                                    slot: "dropdown-icon",
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }