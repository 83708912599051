import Vue from 'vue'
import Router from 'vue-router'
// import { LFeatureGroup } from 'vue2-leaflet';

Vue.use(Router);
// import sampleRouter from './sample/sampleRouter.js'

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
  {
    path: '/404',
    name: '404',
    hidden: true,
    meta: { layout: 'userpages' },
    component: () => import('@/pages/error/404.vue'),
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    meta: { layout: 'userpages' },
    component: () => import('@/pages/login/Login.vue'),
  },
  {
    path: '/ram/compare/compareScenario',
    name: 'compareScenario',
    hidden: true,
    meta: { layout: 'userpages', menulog: 'N', editable: true, },
    component: () => import('@/pages/ram/compare/compareScenario.vue'),
  },
  // {
  //   path: '/',
  //   name: 'main',
  //   hidden: true,
  //   title: '메인화면',
  //   meta: { layout: 'main' },
  //   component: () => import('@/pages/main/index.vue'),
  // },
  {
    path: '/',
    name: 'mainRouter',
    hidden: true,
    meta: { layout: 'route', menulog: 'N' },
    component: () => import('@/pages/main/mainRouter.vue'),
  },
  {
    path: '/sys/menu/manualPage',
    name: 'manual',
    hidden: true,
    title: '매뉴얼',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sys/menu/manualPage.vue'),
  },
  {
    path: '/sys/defect/defectManage',
    name: 'defect',
    hidden: true,
    title: '결함등록',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sys/defect/defectManage.vue'),
  },
  {
    path: '/sop/edu/result/educationResultVodDetail',
    name: 'vod',
    hidden: true,
    title: '교육 VOD',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/sop/edu/result/educationResultVodDetail.vue'),
  },
  // temp
  {
    path: '/origin/icons',
    name: 'icons',
    hidden: true,
    title: 'icons',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/origin/Icons.vue'),
  },
  // 사번이 암호화된 url
  {
    path: '/ssoEncrypt',
    name: 'ssoEncrypt',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/login/ssoEncrypt.vue'),
    hidden: true,
  },
  {
    path: '/ssoFail',
    name: 'ssoFail',
    meta: { layout: 'userpages', menulog: 'N' },
    component: () => import('@/pages/login/ssoFail.vue'),
    hidden: true,
  },
];
/**
 * default route 정보 관리
 *
 * 그 외에 sample route 정보 관리
 * sample은 언제든지 쳐낼수 있도록 소스가 관리되어야 함
 */
// asyncRouterMap = asyncRouterMap.concat(sampleRouter);


export default new Router({
    scrollBehavior() {
      // return { x: 0, y: 0 }
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 400);
      // return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    routes: asyncRouterMap,
    mode: 'history', // # 제거
})
