<template>
  <div class="header-dots">
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl dropdown-menu-header-dot" variant="link" no-caret right>
      <span slot="button-content">
          <q-btn
            flat
            dense
            round
            color="white"
            :class="['topnoti', isBadge ? 'animatedBadge' : '']"
            icon="notifications">
            <!-- <q-badge color="red-custom" rounded floating v-show="isBadge" :class="[isBadge,'animatedBadge']" /> -->
          </q-btn>
      </span>
      <div class="dropdown-menu-header mb-0">
        <div class="dropdown-menu-header-inner dropdown-menu-header-inner2 bg-mini-dialog">
          <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
          <div class="menu-header-content text-white">
            <!-- 알림 -->
            <h5 class="menu-header-title">{{$label('LBL0000850')}}</h5>
          </div>
        </div>
      </div>
      <div class="nav-justified">
        <b-tabs class="card-header-tab-animation" card>
          <b-tab class="p-3" active>
            <template slot="title">
              <!-- 나의 결재 -->
              <span style="cursor:pointer;padding-right: 3px;">{{$label('LBL0000858')}}</span>
              <q-badge rounded color="success" :label="apprCntTotal" />
            </template>
            <div class="scroll-gradient">
              <div class="scroll-area-sm">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-success"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <!-- 결재할 문서 -->
                          <h4 class="timeline-title timeline-title2" style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')">{{$label('LBL0000871')}} </h4>
                          <!-- 결재 -->
                          <p style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')"> {{$label('LBL0000778')}}
                            <q-badge rounded color="success" :label="apprCnt1" />
                          </p>
                          <!-- 합의 -->
                          <p style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')"> {{$label('LBL0000781')}}
                            <q-badge rounded color="success" :label="apprCnt2" />
                          </p>
                          <!-- 확인 -->
                          <p style="cursor:pointer" @click="goPage('/sys/appr/doc/apprTodo')"> {{$label('LBLCONFIRM')}}
                            <q-badge rounded color="success" :label="apprCnt3" />
                          </p>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                    <div class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <!-- 회람함 (최근 1주일) -->
                          <h4 class="timeline-title timeline-title2" style="cursor:pointer" @click="goPage('/sys/appr/doc/apprPass')">{{$label('LBL0000870')}}
                            <q-badge rounded color="warning" :label="apprCnt4" />
                          </h4>
                          <span class="vertical-timeline-element-date"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
          <b-tab class="p-3">
            <template slot="title">
              <!-- 업무 알림 -->
              {{$label('LBL0000859')}}
              <q-badge rounded color="success" :label="taskCntTotal" />
            </template>
            <div class="scroll-gradient">
              <div class="scroll-area-lg">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <template v-if="isTask">
                      <template v-for="(task, idx) in tasks" >
                        <div v-if="task.cnt > 0"
                          :key="idx"
                          class="vertical-timeline-item vertical-timeline-element">
                          <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                            </span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <h4 class="timeline-title">{{task.title}} <q-badge rounded color="success" :label="task.cnt" /></h4>
                              <p v-html="task.subTitle"></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                          </span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <!-- 알림이 없습니다. -->
                            <h4 class="timeline-title">{{$message('MSG0000355')}}</h4>
                            <span class="vertical-timeline-element-date"></span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
          </b-tab>
          <!-- <b-tab class="p-3">
            <template slot="title">
              {{$label('LBL0000860')}}
              <q-badge rounded color="success" :label="pushMessageCntTotal" />
            </template>
            <div class="scroll-gradient">
              <div class="scroll-area-lg">
                <VuePerfectScrollbar class="scrollbar-container">
                  <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <template v-if="isPushMessage">
                      <template v-for="(pushMessage, idx) in pushMessages" >
                        <div v-if="pushMessages.length > 0"
                          :key="idx"
                          class="vertical-timeline-item vertical-timeline-element">
                          <div>
                            <span class="vertical-timeline-element-icon bounce-in">
                              <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                            </span>
                            <div class="vertical-timeline-element-content bounce-in">
                              <span class="deletePush" @click="deletePush(pushMessage)"><i class="material-icons">close</i></span>
                              <h4 class="timeline-title">{{pushMessage.title}} </h4>
                              <p v-html="pushMessage.content"></p>
                              <p v-html="pushMessage.regDt"></p>
                              <span class="vertical-timeline-element-date"></span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="vertical-timeline-item vertical-timeline-element">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in">
                            <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                          </span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">{{$message('MSG0000355')}}</h4>
                            <span class="vertical-timeline-element-date"></span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
            <div style="text-align: center;" v-if="isPushMessage">
              <q-btn-group outline >
                <c-btn label="푸시알림 비우기" icon="delete_forever" color="red-5" @btnClicked="deletePushAll" />
              </q-btn-group>
            </div>
          </b-tab> -->
        </b-tabs>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VuePerfectScrollbar from "vue-perfect-scrollbar";


export default {
  components: {
    VuePerfectScrollbar,
  },

  data() {
    return {
      apprCntUrl: '',
      taskUrl: '',
      pushUrl: '',
      apprCnt1: 0,
      apprCnt2: 0,
      apprCnt3: 0,
      apprCnt4: 0,
      tasks: [],
      pushMessages: [],
    };
  },
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    apprCntTotal() {
      return this.apprCnt1 + this.apprCnt2 + this.apprCnt3 + this.apprCnt4
    },
    taskCntTotal() {
      return this.tasks && this.tasks.length > 0 ?
        this.$_.sum(this.$_.map(this.tasks, 'cnt')) : 0
    },
    pushMessageCntTotal() {
      return this.pushMessages && this.pushMessages.length > 0 ?
        this.pushMessages.length : 0
    },
    isBadge() { //  + this.isPushMessage
      return this.apprCntTotal + this.taskCntTotal > 0 ? true : false
    },
    isTask() {
      return this.taskCntTotal > 0
    },
    // isPushMessage() {
    //   return this.pushMessageCntTotal > 0
    // },
  },
  methods: {
    init() {
      this.apprCntUrl = selectConfig.sys.appr.list.mycnt.url;
      this.taskUrl = selectConfig.com.task.url;
      this.pushUrl = selectConfig.sys.push.history.list.url;
      this.getTask();
      this.getApprCnt();
      // this.getPushMessage();
      if (process.env.NODE_ENV !== 'test') {
        setInterval(() => {
          this.getApprCnt();
        }, 60000);
      }
    },
    goPage(_page) {
      this.$comm.movePage(this.$router, _page)
      var _selectedMenu = document.getElementsByClassName('dropdown-menu-header-dot')[0];
      if (_selectedMenu) {
        _selectedMenu.classList.remove('show');
      }
    },
    goWindowPop(_page) {
      this.$router.push(_page);
      // window.open(_page, '', 'width=1200, height=700');
      // var _selectedMenu = document.getElementsByClassName('dropdown-menu-header-dot')[0];
      // if (_selectedMenu) {
      //   _selectedMenu.classList.remove('show');
      // }
    },
    getApprCnt() {
      this.$http.url = this.apprCntUrl;
      this.$http.isLoading = false;
      this.$http.type = 'GET';
      this.$http.param = {
        approvalUserId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.apprCnt1 = _result.data.apprCnt1;
        this.apprCnt2 = _result.data.apprCnt2;
        this.apprCnt3 = _result.data.apprCnt3;
        this.apprCnt4 = _result.data.apprCnt4;
      },);
    },
    getTask() {
      this.$http.url = this.taskUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        userId: this.$store.getters.user.userId
      };
      this.$http.request((_result) => {
        this.tasks = _result.data
      },);
    },
    // getPushMessage() {
    //   this.$http.url = this.pushUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = {
    //     userId: this.$store.getters.user.userId
    //   };
    //   this.$http.request((_result) => {
    //     this.pushMessages = _result.data
    //   },);
    // },
    deletePush(_push) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '알림을 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sys.push.history.delete.url, _push.pushMessageId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getPushMessage();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    deletePushAll() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '알림 전체를 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sys.push.history.user.url, this.$store.getters.user.userId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.getPushMessage();
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
<style lang="sass">
.topnoti
  width: 26px
  height: 26px
  i
    margin-top: -8px
    margin-left: -6px
  .q-badge
    font-size: 8px !important
    min-width: 8px !important
    min-height: 8px !important
    width: 8px !important
    height: 8px !important
    padding: 0px
    margin-top: 5px
    margin-right: 5px

.deletePush
  cursor: pointer
  width: 40px
  height: 40px
  margin: 0 auto
  float: right
  padding-right: 7px
  i 
    font-size: 1.2em
    color: red
    font-weight: 600
</style>
