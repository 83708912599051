/* eslint-disable */
import comm from '@/js/common.js';

const methods = {
  /**
   * 라벨코드에 따른 라벨명 반환
   * @param {*} label 
   * @returns 언어별 라벨코드에 따른 라벨명
   */
  getLabel(label) {
    return comm.getLangLabel(label);
  },
  /**
   * 메시지코드에 따른 메시지명 반환
   * @param {*} label 
   * @returns 언어별 메시지코드에 따른 메시지명
   */
  getMessage(message) {
    return comm.getLangMessage(message);
  }
}

export default {
  install(Vue) {
    Vue.prototype.$label = methods.getLabel;
    Vue.prototype.$message = methods.getMessage;
  }
}