<template>
  <div :class="sidebarbg" class="app-sidebar sidebar-shadow"
    @mouseover="toggleSidebarHover('add','closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove','closed-sidebar-open')">
    <div class="app-header__logo">
      <div :class="logoClass" @click="routeMain" />
    </div>
    <div class="app-sidebar-content horizon-side-menu-area">
      <VuePerfectScrollbar class="app-sidebar-scroll left-sidebar-menu" >
        <c-sidebar-menu showOneChild :menu="permission_routers" />
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters } from 'vuex'
export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      isOpen: false,
      sidebarActive: false,
      collapsed: true,
      windowWidth: 0,
      logoClass: 'logo-src cursor-pointer'
    };
  },
  mounted() {
    if (this.$store.getters.user.loginId == 'cmms') {
      this.logoClass = 'logo-src-cmms cursor-pointer';
    }
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  props: {
    sidebarbg: String
  },
  computed: {
    ...mapGetters([
      'permission_routers',
    ]),
  },
  methods: {
    toggleSidebarHover(add, className) {
      const el = document.body;
      this.sidebarActive = !this.sidebarActive;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth > "992") {
        if (add === "add") {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      }
    },
    getWindowWidth() {
      const el = document.body;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < "1350") {
        el.classList.add("closed-sidebar", "closed-sidebar-md");
      } else {
        el.classList.remove("closed-sidebar", "closed-sidebar-md");
      }
    },
    routeMain() {
      if (this.$route.path !== this.$store.state.systemInfo.mainUrl) {
        this.$router.push('/');
      }
    },
  },
};
</script>
